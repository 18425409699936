import React, { useEffect, useState } from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import Form from "../components/form";

const FormSection = props => {
  const formType = props.formType;
  const image = props.image[0].image;
  const packages = props.packages;

  return (
    <div className="form-section">
      <div
        className={
          formType === "cycling"
            ? "form-section__wrapper form-section__wrapper--left"
            : "form-section__wrapper"
        }
      >
        <div className="form-section__image-wrapper">
          {image && image.asset && (
            <img
              className={`form-section__image `}
              src={imageUrlFor(buildImageObj(image))
                .width(formType === "cycling" ? 850 : 600)
                .height(600)
                .fit("crop")
                .auto("format")
                .url()}
              alt={image.alt}
            />
          )}
        </div>
        <div className="form-section__form-wrapper">
          <h3 className="form-section__order-heading">Bestilling</h3>
          <Form packages={packages} formType={formType} />
        </div>
      </div>
    </div>
  );
};

export default FormSection;
