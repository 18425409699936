import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../lib/helpers";
import SEO from "../../components/seo";
import Container from "../../components/container";
import Elevator from "../../components/elevator";
import PtPackage from "../../components/ptPackage";
import FormSection from "../../components/formSection";
import Layout from "../../components/layout";
import TestimonialShowcase from "../../components/testimonialShowcase";

import "../../styles/layout.scss";

export const query = graphql`
  query cyclingCoachingPageQuery {
    runningCoaching: allSanityPage(filter: { name: { eq: "Sykkelcoaching" } }) {
      edges {
        node {
          name
          _rawBody
          _rawExtraBody
          _id
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
    ptPackages: allSanityCyclePtPackage {
      edges {
        node {
          _id
          _rawDescription
          _rawIncludes
          mainImage {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
          title
          price
        }
      }
    }
    formImages: allSanitySlider {
      edges {
        node {
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
  }
`;

const CyclingCoaching = () => {
  const data = useStaticQuery(query);
  const pageEdge = mapEdgesToNodes(data.runningCoaching);
  const elevatorContent = pageEdge[0] || [];

  const packages = mapEdgesToNodes(data.ptPackages);

  const formImages = mapEdgesToNodes(data.formImages);

  const cycleImage = formImages.filter(
    image =>
      image.image.asset._id === "image-ce5c488781407214f3079e49c5fa48bcc57c6e88-7139x5353-jpg"
  );

  return (
    <Layout>
      <SEO title="Sykkelcoaching" />
      <div className="elevator__background"></div>
      <Container>
        <div className="cycling-coaching">
          <Elevator
            title={elevatorContent.name}
            image={elevatorContent.image}
            info={elevatorContent._rawBody}
            extraInfo={elevatorContent._rawExtraBody}
          />
          <h3 className="cycle-coaching__packages">PT pakker</h3>
          <div className="cycle-coaching__wrapper">
            {packages && packages.map(program => <PtPackage program={program} />)}
          </div>
        </div>
      </Container>
      <FormSection packages={packages} image={cycleImage} formType="cycling" />
      <TestimonialShowcase />
    </Layout>
  );
};

export default CyclingCoaching;
