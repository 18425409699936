import React, { useState } from "react";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

const PtPackage = ({ program }) => {
  const { title, mainImage, _rawIncludes, price, _rawDescription } = program;
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="pt-package" onClick={handleIsOpen}>
      <div className="pt-package__wrapper">
        <div className="pt-package__title-container">
          <h3 className="pt-package__title">{title}</h3>
        </div>

        {mainImage && mainImage.asset && (
          <img
            className="pt-package__img"
            src={imageUrlFor(buildImageObj(mainImage))
              .width(600)
              .height(600)
              .fit("crop")
              .auto("format")
              .url()}
            alt={mainImage.alt}
          />
        )}

        <div
          className={`pt-package__info ${
            isOpen ? "pt-package__info--open" : "pt-package__info--closed"
          }`}
        >
          <div className="pt-package__description">
            <span className="pt-package__info-heading">Beskrivelse</span>
            {_rawDescription && <PortableText blocks={_rawDescription} />}
          </div>
        </div>
        <span className="pt-package__price">{price}</span>
      </div>
    </div>
  );
};

export default PtPackage;
