import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Form = ({ packages, formType }) => {
  let formName = "Bestilling";
  if (formType === "cycling") {
    formName = "Bestilling Sykkelprogram";
  } else if (formType === "running") {
    formName = "Bestilling Løpeprogram";
  }
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log(state);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };
  return (
    <form
      className="form"
      name={formName}
      method="post"
      data-netlify="true"
      netlify
      action="/takk/"
      onSubmit={handleSubmit}
      //autoComplete="off"
    >
      <input
        type="hidden"
        name="form-name"
        value={formName} //autoComplete="false"
      />
      <p>
        <label className="form__label">
          Navn: <input onChange={handleChange} type="text" name="name" />
        </label>
      </p>
      <p>
        <label className="form__label">
          Email: <input onChange={handleChange} type="email" name="email" />
        </label>
      </p>
      <p>
        <label className="form__label">
          PT-Pakke:{" "}
          <select onChange={handleChange} name="PT-Pakke[]">
            <option value="" disabled selected>
              Velg pakke
            </option>
            {packages &&
              [...packages]
                .sort((a, b) => {
                  const aContains20 = a.title.includes("20");
                  const bContains20 = b.title.includes("20");

                  if (aContains20 && !bContains20) {
                    return 1; // 'a' should be after 'b'
                  }
                  if (!aContains20 && bContains20) {
                    return -1; // 'a' should be before 'b'
                  }
                  return 0; // 'a' and 'b' are equal
                })
                .map((program) => (
                  <option key={program._id} value={program.title}>
                    {program.title}
                  </option>
                ))}
          </select>
        </label>
      </p>
      <p>
        <label className="form__label">
          Melding: <textarea onChange={handleChange} name="message" />
        </label>
      </p>

      <p>
        <button className="form__button" type="submit">
          Bestill
        </button>
      </p>
    </form>
  );
};

export default Form;
